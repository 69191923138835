import Vue from 'vue'
import Vuex from 'vuex'

import user from './user/user'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    user
  },
  strict: false // process.env.NODE_ENV !== 'production'
})
