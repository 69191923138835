<template>
  <div>
    <div class="center" v-if="showLoading">
		<lottie :options="defaultOptionsSplashScreen" :height="400" :width="400" v-on:animCreated="handleAnimationSplashScreen"/>
	</div>
    <div class="container-top" v-else>
	    <div class="row" style="background-color:#141D26;">
            <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
            <div class="bg-stark-img col-xs-12 col-sm-12 col-md-10 col-lg-8">
                <div class="row">
                    <lottie :options="defaultOptionsCenter" :height="180" :width="200" v-on:animCreated="handleAnimationCenter"/>
                </div>
                <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; align-items: center; justify-content: center; display: flex;">
                    <lottie :options="defaultOptionsCenter2" :height="180" :width="200" v-on:animCreated="handleAnimationCenter2"/>
                </div>
                <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; align-items: center; justify-content: center; display: flex;">
                    <div class="xbox" :style="`text-shadow: 0px 0px 10px ${percentcolor};`" data-char="10" id="value"></div>
                </div>
            </div>
            <div style="position: absolute; top: 20px; align-items: center; justify-content: center; right: 4px;">
                <lottie :options="defaultOptionsCountdown" :height="100" :width="100" style="ma" v-on:animCreated="handleAnimationCountdown"/>
            </div>

            <div style="position: absolute; top: 130px; align-items: center; justify-content: center; right: 0;">
                  <a href="#" @click="goback"><img src="../assets/images/hackslot/btn-switch.png" width="140" height="59" class="text-center img-responsive"></a>
            </div>

		    <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
	    </div>
    </div>
    <div v-if="!showLoading">
        <iframe v-if="url" :src="url" class="iframegame" title="games"></iframe> 
    </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
import Lottie from './lottie.vue'
import * as animateSantaWalk from '../assets/scan15s.json'
import * as animateScan from '../assets/circle.json'
import * as animateScan2 from '../assets/circle2.json'
import * as animateCountdown from '../assets/countdown.json'
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Huay',
  created () {
    document.body.style.backgroundColor = '#ffffff'
    this.defaultOptionsSplashScreen = { animationData: animateSantaWalk.default, autoplay: false, loop: true }
    this.defaultOptionsCenter = { animationData: animateScan.default, autoplay: false, loop: true }
    this.defaultOptionsCenter2 = { animationData: animateScan2.default, autoplay: false, loop: true }
    this.defaultOptionsCountdown = { animationData: animateCountdown.default, autoplay: false, loop: true }
  },
  mounted () {
    if (!firebase.auth().currentUser) {
      router.replace('/')
    } else {
      this.getUserId()
      this.animSplashScreen.play()
      setTimeout(() => {
        this.showLoading = false
        setTimeout(() => {
            this.animSplashScreen.stop()
            this.animCenter.play()
            this.animCenter2.play()
            this.animCountdown.play()
            // this.animScan2.play()
            this.randomPercent(70,99)
            setInterval(() => {
                this.countdown--
                if(this.countdown == 0){
                    this.countdown = 12
                    this.randomPercent(60,99)
                }
            }, 1000)
        }, 500)
      }, 1000)
      const fullurl = localStorage.getItem('url')
      const url = fullurl.split('/')
      if(url[2]){
          if(url[2]==='nttech-sg0.ppgames.net' && url[4]){
              const names = url[4].split('&');
              if(names[1]){
                  this.name = decodeURI(names[1].replace('gname=',''));
              }
          }else if(url[2]==='game.3yjt.com'){
              this.name = 'KING MAKER';
          }else if(url[2]==='lobby.silverkirinplay.com' && url[7]){
              this.name = url[7].toUpperCase()
          }else if(url[2]==='egame.multi888.com'){
              this.name = 'egame.multi888'.toUpperCase()
          }else if(url[2]==='play.pgslot.co'){
              this.name = 'PG GAMES'.toUpperCase()
          }else if(url[2]==='www.gwc688.net'){
              this.name = 'XO SLOT';
          }else if(url[2]==='games.ambslot.com'){
              this.name = 'AMB SLOT';
          }else if(url[2]==='lobby.argonplay.com'){
              this.name = 'Spade gaming';
          }else if(url[2]==='funky.ttstations.com'){
              this.name = 'Funky game';
          }else if(url[2]==='wsx.mayousa.com'){
              this.name = 'Funta gaming';
          }else if(url[2]==='gamelaunch.wazdan.com'){
            this.name = 'Wazdan direct';
          }else if(url[2]==='wbgame.sssjlsss.com'){
            this.name = 'Jili';
          }else if(url[2]==='lobby-ahvgl62wwq-df.a.run.app'){
            this.name = 'Iconic gaming';
          }else if(url[2]==='booongo2-dgm.gv-gamespace.com'){
            this.name = 'Booongo';
          }else if(url[2]==='purple.romadeluxe.com'){
            this.name = 'Allwayspin';
          }else if(url[2]==='629xyss.fafafa3388.com'){
            this.name = 'Ameba';
          }else if(url[2]==='cors.mannagaming.com'){
            this.name = 'Manna play';
          }else if(url[2]==='gamessea.kaga88.com'){
            this.name = 'KAgaming';
          }else if(url[2]==='static.shycfjwz.com'){
            this.name = 'Askmebet';
          }else if(url[2]==='static-prod-tw.dcgames.fun'){
            this.name = 'Yggdrasil';
          }else if(url[2]==='frerpt.kathilag.com'){
            this.name = 'Micro gaming';
          }else if(url[2]==='w1.bluecave88.com'){
            this.name = 'Live22';
          }else if(url[2]==='sg.sp-platform.com'){
            this.name = 'Simpleplay';
          }else if(url[2]==='play.8provider.com'){
            this.name = 'Evo play';
          }else if(url[2]==='api.ayitang.com'){
            this.name = 'Gametron';
          }else{
              this.name = url[3].toUpperCase()
          }
      }
      this.url = fullurl
      // setTimeout(() => {
      //     var iFrame = document.getElementById( 'iFrame1' );
      //   this.resizeIFrameToFitContent( iFrame );

      //   // or, to resize all iframes:
      //   // var iframes = document.querySelectorAll("iframe");
      //   // for( var i = 0; i < iframes.length; i++) {
      //   //     resizeIFrameToFitContent( iframes[i] );
      //   // }
      // }, 2000);
    }
    this.getGames()
  },
  data () {
    return {
      countdown:10,
      percentcolor:'#04eb3d',
      percent:0,
      percentOld:0,
      error:'',
      url:'',
      name:'',
      logoRight:'',
      gameKey: 'hackslot',
      huays:[],
      rows:20,
      showLoading: true,
      pictureUrl: '',
      code: '',
      user: [],
      games_web: [],
      search: '',
      loading: false,
      web: [],
      point: 0,
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext:'น้องออโต้กำลังประมวลผลให้อยู่ค่ะ รอสักครู่',
      credit:'',
      save:false,
      QTY:0,
      countQTY:0,
      oldQTY:0,
      isDisabled:false,
      duration:'',
    }
  },
  components: {
    lottie: Lottie
  },
  methods: {
    logout(){
        this.$confirm(
          {
            message: `ต้องการล็อกเอาท์จากระบบ`,
            button: {
              yes: 'Yes',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                firebase.auth().signOut().then(()=>{
                  router.replace('/')
                })
              }
            }
          }
        );
    },
    resizeIFrameToFitContent( iFrame ) {
      iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
      iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    },

    animateValue(obj, start, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
            window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    },
    randomPercent(min, max){
        this.percent = Math.floor(Math.random() * (max - min) ) + min
        if(this.percent<70){
            this.percentcolor = '#ed1c25'
        }else if(this.percent<80){
            this.percentcolor = '#ffd919'
        }else{
            this.percentcolor = '#04eb3d'
        }
        const obj = document.getElementById("value")
        this.animateValue(obj, this.percentOld,this.percent, 1000)
        this.percentOld = this.percent
    },
    goback(){
            this.$router.replace('/hackslot');
    },
    selectNumber(num){
        if(this.huays[num]){
            this.huays[num] = false;
            this.countQTY--;
        }else if(this.QTY > this.countQTY){
            this.huays[num] = true;
            this.countQTY++;
        }
        this.rows++;
        this.rows--;
    },
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    handleAnimationCenter: function (anim) {
        this.animCenter = anim
    },
    handleAnimationCenter2: function (anim) {
        this.animCenter2 = anim
    },
    handleAnimationBackground: function (anim) {
        this.animBackground = anim
    },
    handleAnimationCountdown: function(anim){
        this.animCountdown = anim
    },
    renderNumber(row,i){
     const num = ((row-1)*5)+i;
     if(num.toString().length == 1){
         return '0'+num;
     }else{
         return num.toString();
     }
    },
    async getGames () {
      this.games_web = (await firebase.database().ref(`/games_web/${this.$projectId}/${this.gameKey}`).once('value')).val()
      firebase.database().ref(`/games/${this.gameKey}`).once('value').then((snapshot) => {
        if (!snapshot.val().status || !this.games_web.status) {
          router.replace('/games')
        }
      })
    },
    async getUserId () {
      this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val()
      if (this.web) {
        // if(this.web.login_image)
        //   this.pictureUrl = this.web.login_image;
        // else
        //   this.pictureUrl = require('../assets/images/hackslot/2092839.jpg')
        // if(this.web.img_left)
        //   this.logoLeft = this.web.img_left
        // else
        //   this.logoLeft = require('../assets/images/casino.png')
        // if(this.web.img_right)
        //   this.logoRight = this.web.img_right
        // else
        //   this.logoRight = require('../assets/images/contact.png')
        const email = firebase.auth().currentUser.email.split('@')
        const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val()
        this.user = users[Object.keys(users)[0]]
        this.user.key = Object.keys(users)[0]
        if (this.user) {
          this.point = this.user.point
          this.code = this.user.code
          if(this.user.games[this.gameKey] && this.user.games[this.gameKey].expiredate){
            this.expiredate = this.user.games[this.gameKey].expiredate
            if(Number(this.expiredate) <= moment().tz('Asia/Bangkok').format('YYYYMMDDHHmmss')){
                    router.replace('/games')
            }
          }else{
              router.replace('/games')
          }
        //   } else {
        //     this.canOpen = false
        //   }
        } else {
          router.replace('/')
        }
       var styleElem = document.head.appendChild(document.createElement('style'))
       styleElem.innerHTML = `#contact_gl:before {background: #141D26;}`
      } else {
        router.replace('/')
      }
    },
    checkCssBox (game) {
      if (!game.status) {
        return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
      }
    },
    checkCss (game) {
      if (!this.permission || !this.permission[game.key]) {
        return 'filter: grayscale(100%);'
      }
      // const index = _.findIndex(this.favorites, function(o) { return o == code; });
      // if (index>-1) {
      //   return '';
      // } else {
      //   return 'filter: grayscale(100%);';
      // }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/games').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    },
    getSpinClass () {
      if (!this.loading) { return 'fas fa-sync-alt f-9' } else { return 'fas fa-sync-alt fa-spin f-9' }
    },

    numberWithCommas (x) {
      return Number(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    openGame (game) {
      if (this.permission[game.key]) {
        if (game.key === 'gift') {
          this.$router.push('/gift').catch(() => {})
        }
      }
    }
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
a.button1{
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
    text-decoration: none;
    font-size: 18px;
    width: 70px;
}
.active{
    color:#000000 !important;
    background-color:#FFFFFF !important;
    text-decoration: none !important;
}
div.rowClass {
    flex-direction: row;
    justify-content: space-around;
    display: flex;
}
.iframegame{
  width:100%;height:75%;border:0px;position: absolute;
}
@media (max-height: 736px) {
  .iframegame{
    width:100%;height:75%;border:0px;position: absolute;
  }
}
@media (max-height: 600px) {
  .iframegame{
    width:100%;height:70%;border:0px;position: absolute;
  }
}
@media all and (max-width:30em){
    a.button1{
        margin:0.4em auto;
    }
}
.btn2 {
  width: 100%;
    font-size: 18px;
    border-radius: 25px;
    color: #fff;
    border: none;
    padding: .5rem 1.5rem;
    min-width: 180px;
    padding: .85rem 2.5rem;
    background: linear-gradient(
180deg
,#91f27f 10%,#00b302);
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 1px 2px hsl(0deg 7% 92% / 24%);
}
.md-title {
    color:#fff;
    padding-top: 15px;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
.xbox {
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -51%);
  color: #e7e7ec;
}
.xbox .inside {
  font-size: 50px;
  background-image: linear-gradient(180deg, white 10%, #171717 100%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: none;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 99;
}
/* .xbox:before {
  content: attr(data-char);
  font-size: 400px;
  position: absolute;
  text-shadow: 0px 0px 5px #00ff56;
  clip-path: polygon(
    0% 100%,
    0% 0%,
    100% 0%,
    100% 50%,
    50% 50%,
    100% 50%,
    100% 100%
  );
  animation-name: loading;
  animation-duration: 10s;
  animation-delay: -0.1s;
  animation-timing-function: cubic-bezier(0, 0.1, 0.9, 0.81);
  animation-direction: reverse;
  mix-blend-mode: color;
} */
/* .xbox:after {
  content: attr(data-char);
  font-size: 400px;
  position: absolute;
  text-shadow: 0px 0px 5px black;
  clip-path: polygon(
    0% 100%,
    0% 0%,
    100% 0%,
    100% 50%,
    50% 50%,
    100% 50%,
    100% 100%
  );
  animation-name: loading;
  animation-duration: 10s;
  animation-timing-function: cubic-bezier(0, 0.1, 0.9, 0.81);
  animation-direction: reverse;
  mix-blend-mode: color;
  left: 0;
  top: 0;
} */
@keyframes loading {
  0% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      100% 50%,
      100% 100%
    );
  }
  12.5% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      100% 100%,
      100% 100%
    );
  }
  25% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      50% 100%,
      50% 100%
    );
  }
  37.5% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      0% 100%,
      0% 100%
    );
  }
  50% {
    clip-path: polygon(
      0% 50%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      0% 50%,
      0% 50%
    );
  }
  62.5% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 50%, 50% 50%, 0% 0%, 0% 0%);
  }
  75% {
    clip-path: polygon(
      50% 0%,
      50% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      50% 0%,
      50% 0%
    );
  }
  87.5% {
    clip-path: polygon(
      100% 0%,
      100% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      100% 0%,
      100% 0%
    );
  }
  100% {
    clip-path: polygon(
      100% 50%,
      100% 50%,
      100% 50%,
      100% 50%,
      50% 50%,
      100% 50%,
      100% 50%
    );
  }
}
@keyframes loading2 {
  0% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      100% 50%,
      100% 100%
    );
  }
  12.5% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      100% 100%,
      100% 100%
    );
  }
  25% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      50% 100%,
      50% 100%
    );
  }
  37.5% {
    clip-path: polygon(
      0% 100%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      0% 100%,
      0% 100%
    );
  }
  50% {
    clip-path: polygon(
      0% 50%,
      0% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      0% 50%,
      0% 50%
    );
  }
  62.5% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 50%, 50% 50%, 0% 0%, 0% 0%);
  }
  75% {
    clip-path: polygon(
      50% 0%,
      50% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      50% 0%,
      50% 0%
    );
  }
  87.5% {
    clip-path: polygon(
      100% 0%,
      100% 0%,
      100% 0%,
      100% 50%,
      50% 50%,
      100% 0%,
      100% 0%
    );
  }
  100% {
    clip-path: polygon(
      100% 50%,
      100% 50%,
      100% 50%,
      100% 50%,
      50% 50%,
      100% 50%,
      100% 50%
    );
  }
}

</style>
<style lang="scss" scoped>
$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #fff;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 90%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary,$secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; max-width: 1150px;}
}
/* demo */
$fuschia: #2c4054;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

body{
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-align: center;
  background-color: $baby-blue;
}
.bubbly-button{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
  margin-top: 100px;
  margin-bottom: 60px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
  
  &:focus {
    outline: 0;
  }
  
  &:before, &:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  
  &:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  &:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
 
  &:active{
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }
  
  &.animate{
    &:before{
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after{
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}


@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
 100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
 100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
</style>